<script setup>
import { price } from '@/Helpers/formatters';
import Checkbox from '@/Components/Shared/Forms/Checkbox.vue';
import InputLabel from '@/Components/Shared/Forms/InputLabel.vue';
import { computed } from 'vue';

const props = defineProps({
    modelValue: Array,
    group: Object,
});

const emit = defineEmits(['update:modelValue']);

const proxy = computed({
    get() {
        return props.modelValue;
    },
    set(val) {
        emit('update:modelValue', val);
    },
});
</script>

<template>
    <div class="block">
        <div class="flex items-center" v-for="option in group.options" :key="option.id" :dusk="`group-${group.id}`">
            <Checkbox class="checkbox" :id="`option-${option.id}`" :name="`option[${group.id}][]`" :value="option.id" v-model:checked="proxy" :dusk="`option-${option.id}`">
                <InputLabel :for="`option-${option.id}`">
                    {{ option.name }}
                    <span v-if="option.price_incl_vat !== 0">({{ price(option.price_incl_vat) }})</span>
                </InputLabel>
            </Checkbox>
        </div>
    </div>
</template>
