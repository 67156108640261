<script setup>
import 'swiper/css';
import { register } from 'swiper/element/bundle';
import { ref } from 'vue';
import Button from '@/Components/Shared/Forms/Button.vue';
import ProductBlock from '@/Components/Shared/Restaurant/ProductBlock.vue';
import Card from '@/Components/Shared/Cards/Card.vue';
import { TransitionRoot } from '@headlessui/vue';

register();

defineProps({
    products: Array,
    optionGroups: Array,
    transportation: String,
});

const isShowing = ref(true);
const toggle = () => isShowing.value = !isShowing.value;
</script>

<template>
    <div class="isolate mb-8 scroll-mt-20" id="uitgelicht" dusk="category-uitgelicht">
        <Card class="cursor-pointer hover:bg-gray-50 transition-colors ease-in-out duration-150 relative z-10 mb-4" @click="toggle">
            <div class="p-3">
                <div class="flex flex-row justify-between">
                    <div class="my-auto">
                        <h2 class="font-bold text-gray-700 text-2xl">Uitgelicht</h2>
                    </div>

                    <div>
                        <button class="bg-blue-200 hover:bg-blue-300 text-blue-500 px-4 py-2 aspect-square rounded-lg transition-transform" dusk="toggle">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="fill-blue-500 w-[16px] h-[16px] transition-transform" :class="{ '-rotate-180': isShowing }">
                                <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                                <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/>
                            </svg>
                            <span class="sr-only">{{ isShowing ? 'Sluit categorie' : 'Open categorie' }}</span>
                        </button>
                    </div>
                </div>
            </div>
        </Card>

        <TransitionRoot
            :show="isShowing"
            enter="transform transition duration-250"
            enter-from="-translate-y-48 opacity-0"
            enter-to="translate-y-0 opacity-100"
            leave="transform transition duration-250"
            leave-from="translate-y-0 opacity-100"
            leave-to="-translate-y-48 opacity-0"
        >
            <div
                class="grid grid-cols-1 grid-cols-1 lg:grid-cols-2 gap-2"
            >
                <ProductBlock
                    v-for="product in products"
                    :key="product.id"
                    :option-groups="optionGroups.filter(group => product.option_groups.map(group => group.id).includes(group.id))"
                    :product="product"
                    :transportation="transportation"
                    layout="horizontal"
                    :lazy-load="false"
                    class="relative z-0"
                />
            </div>
        </TransitionRoot>
    </div>
</template>
