<script setup>
import Card from '@/Components/Shared/Cards/Card.vue';

defineProps({
    categories: Array,
    currentCategory: String,
});

const emit = defineEmits(['toggle-menu']);
const toggleMenu = () => emit('toggle-menu');
</script>

<template>
    <Card as="aside" class="divide-y" dusk="menu-nav">
        <div class="flex justify-between p-3">
            <h1 class="font-bold text-2xl text-gray-700">Menukaart</h1>

            <button class="xl:hidden flex w-8 h-8 justify-center items-center bg-white hover:bg-gray-100 aspect-square rounded-lg my-auto" @click="toggleMenu" dusk="close">
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512" class="w-[24px] h-[24px] fill-gray-700">
                    <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                    <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
                </svg>
            </button>
        </div>

        <div class="max-h-[60vh] overflow-auto">
            <nav>
                <ul class="divide-y">
                    <template v-for="category in categories" :key="category.id">
                        <li class="hover:bg-orange-100 last:rounded-b-xl" v-if="category.products.length > 0">
                            <a
                                :href="`#${category.slug}`"
                                class="block p-4"
                                :dusk="`menu-nav-${category.id}`"
                                :class="{ 'bg-orange-100': currentCategory === category.id }">{{ category.name }}</a>
                        </li>
                    </template>
                </ul>
            </nav>
        </div>
    </Card>
</template>
