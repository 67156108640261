<script setup>
import { price } from '@/Helpers/formatters';
import { computed } from 'vue';
import Select from '@/Components/Shared/Forms/Select.vue';

const props = defineProps({
    modelValue: Array,
    group: Object,
});

defineEmits(['update:modelValue']);

const options = computed(() => {
    let options = props.group.options.map(option => ({
        text: option.name + (option.price_incl_vat !== 0 ? ' ('+price(option.price_incl_vat)+')' : ''),
        value: option.id
    }));

    if (! props.group.is_required) {
        options.unshift({
            text: 'Geen selectie',
            value: '',
        });
    }

    return options;
});
</script>

<template>
    <div class="block">
        <Select class="block w-full" :items="options" :id="`group-${group.id}`" :name="`group[${group.id}]`" @input="$emit('update:modelValue', $event.target.value)" :dusk="`group-${group.id}`" />
    </div>
</template>
