<script setup>
import { useForm, usePage } from '@inertiajs/vue3';
import route from '@/Helpers/route.js';
import Fieldset from '@/Components/Shared/Forms/Fieldset.vue';
import ProductPrice from '@/Components/Shared/Restaurant/Product/ProductPrice.vue';
import QuantityInput from '@/Components/Shared/Restaurant/Product/QuantityInput.vue';
import ProductTitle from '@/Components/Shared/Restaurant/Product/ProductTitle.vue';
import InputError from '@/Components/Shared/Forms/InputError.vue';
import { ref } from 'vue';

const page = usePage();

const props = defineProps({
    product: Object,
});

const showAddAnimationCross = ref(false);

const formCross = useForm({
    quantity: 1,
});

const createItemCross = async () => {
    formCross.post(route(`${page.props.routing_group}.cart.create`, {
        [page.props.routing_key]: page.props.routing_target,
        product: props.product.id
    }), {
        preserveState: true,
        preserveScroll: true,
        onSuccess: () => {
            showAddAnimationCross.value = true;
            setTimeout(() => {
                showAddAnimationCross.value = false;
            }, 250);
        },
        only: ['cart', 'errors'],
    });
};
</script>

<template>
    <Fieldset class="text-left mb-0! flex flex-col">
        <div class="flex flex-row gap-4 mb-2">
            <div class="max-w-[100px] flex" v-if="product.links.image">
                <img class="object-contain" :src="product.links.image" :srcset="product.links.responsive_image" :alt="product.name" loading="lazy">
            </div>
<!--            <div class="w-full max-w-[100px]" v-else></div>-->

            <div class="flex flex-col mx-auto" :class="{ 'grow': product.links.image }">
                <ProductTitle :title="product.name" class="text-base! xl:text-lg!" />
                <ProductPrice :price="product.total_unit_price" :deposit="product.deposit" class="text-base! mb-2" />

                <div class="mb-4">
                    <QuantityInput variant="small" v-model.number="formCross.quantity" />
                    <InputError class="mt-2" :message="formCross.errors.quantity" />
                </div>
            </div>
        </div>

        <button class="flex w-full h-8 bg-white mt-auto justify-center items-center cursor-pointer font-medium rounded-sm border transition-[border-color] ease-in-out duration-150" :class="{ 'border-blue-500 text-white px-2 hover:border-blue-600 active:border-blue-600': !showAddAnimationCross, 'px-4 border-green-500 text-white hover:border-green-600 active:border-green-600': showAddAnimationCross, 'px-4': formCross.processing}" @click="createItemCross" dusk="add-cross-to-cart">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="fill-blue-500 w-[16px] h-[16px] animate-spin" v-if="formCross.processing"> <!-- loading -->
                <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                <path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z"/>
            </svg>

            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="fill-green-500 w-[16px] h-[16px]" v-if="!formCross.processing && showAddAnimationCross"> <!-- check -->
                <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/>
            </svg>

            <span :class="{ 'text-blue-500': !showAddAnimationCross }" v-if="!formCross.processing && !showAddAnimationCross">
                Toevoegen
            </span>
        </button>
    </Fieldset>
</template>
