<script setup>
import { computed, ref } from 'vue';
import { useForm, usePage } from '@inertiajs/vue3';
import Card from '@/Components/Shared/Cards/Card.vue';
import Dialog from '@/Components/Shared/Dialogs/Dialog.vue';
import Fieldset from '@/Components/Shared/Forms/Fieldset.vue';
import InputError from '@/Components/Shared/Forms/InputError.vue';
import Legend from '@/Components/Shared/Forms/Legend.vue';
import AddToCartButton from '@/Components/Shared/Restaurant/Product/AddToCartButton.vue';
import AlcoholicBadge from '@/Components/Shared/Restaurant/Product/AlcoholicBadge.vue';
import HalalBadge from '@/Components/Shared/Restaurant/Product/HalalBadge.vue';
import InvalidTransportationBadge from '@/Components/Shared/Restaurant/Product/InvalidTransportationBadge.vue';
import MultipleChoiceOptionsSelector from '@/Components/Shared/Restaurant/Product/MultipleChoiceOptionsSelector.vue';
import OptionalSingleChoiceOptionSelector
    from '@/Components/Shared/Restaurant/Product/OptionalSingleChoiceOptionSelector.vue';
import ProductAllergies from '@/Components/Shared/Restaurant/Product/ProductAllergies.vue';
import ProductDescription from '@/Components/Shared/Restaurant/Product/ProductDescription.vue';
import ProductId from '@/Components/Shared/Restaurant/Product/ProductId.vue';
import ProductImage from '@/Components/Shared/Restaurant/Product/ProductImage.vue';
import ProductLabel from '@/Components/Shared/Restaurant/Product/ProductLabel.vue';
import ProductPrice from '@/Components/Shared/Restaurant/Product/ProductPrice.vue';
import ProductTitle from '@/Components/Shared/Restaurant/Product/ProductTitle.vue';
import QuantityInput from '@/Components/Shared/Restaurant/Product/QuantityInput.vue';
import RequiredSingleChoiceOptionSelector
    from '@/Components/Shared/Restaurant/Product/RequiredSingleChoiceOptionSelector.vue';
import SpicyBadge from '@/Components/Shared/Restaurant/Product/SpicyBadge.vue';
import { price } from '@/Helpers/formatters';
import route from '@/Helpers/route';

const page = usePage();

const props = defineProps({
    product: Object,
    optionGroups: Array,
    transportation: String,
    layout: String,
    lazyLoad: Boolean,
});

const form = useForm({
    quantity: 1,
    option_groups: props.optionGroups.reduce(
        (carry, group) => ({
            ...carry,
            [group.id]: group.type === 'single_choice' ? null : [],
        }),
        {},
    ),
});

const formCross = useForm({
    quantity: 1,
});

const createItem = async () => {
    form.post(
        route(`${page.props.routing_group}.cart.create`, {
            [page.props.routing_key]: page.props.routing_target,
            product: props.product.id,
        }),
        {
            preserveState: true,
            preserveScroll: true,
            onSuccess: () => {
                showAddAnimation.value = true;
                setTimeout(() => {
                    showAddAnimation.value = false;
                    setDialogIsOpen(false);
                }, 250);
            },
            only: ['cart', 'errors'],
        },
    );
};

const createItemCross = async () => {
    formCross.post(
        route(`${page.props.routing_group}.cart.create`, {
            [page.props.routing_key]: page.props.routing_target,
            product: props.product.cross_sell_product.id,
        }),
        {
            preserveState: true,
            preserveScroll: true,
            onSuccess: () => {
                showAddAnimationCross.value = true;
                setTimeout(() => {
                    showAddAnimationCross.value = false;
                }, 250);
            },
            only: ['cart', 'errors'],
        },
    );
};

const showAddAnimation = ref(false);
const showAddAnimationCross = ref(false);

const isDialogOpen = ref(false);
const isImageOpen = ref(false);

const isAvailable = computed(
    () =>
        (props.transportation === 'delivery' && props.product.for_delivery) ||
        (props.transportation === 'pickup' && props.product.for_pickup) ||
        props.transportation === null,
);

const setDialogIsOpen = (value) => {
    if (isAvailable.value) {
        isDialogOpen.value = value;
    }
};

const setImageIsOpen = (value) => {
    isImageOpen.value = value;
};

const totalPrice = computed(() => {
    let product = props.product.total_unit_price;

    let options = props.optionGroups.reduce((carry, group) => {
        let current = 0;

        if (group.type === 'multiple_choice') {
            group.options.forEach((option) => {
                if (form.option_groups[group.id].includes(option.id)) {
                    current += option.price_incl_vat;
                }
            });
        } else if (group.type === 'single_choice') {
            group.options.forEach((option) => {
                if (option.id === form.option_groups[group.id]) {
                    current += option.price_incl_vat;
                }
            });
        }

        return carry + current;
    }, 0);

    return (product + options) * form.quantity;
});
</script>

<template>
    <div v-bind="$attrs">
        <Card
            class="flex flex-col hover:bg-gray-50 transition-colors ease-in-out duration-150"
            :class="{
                'cursor-pointer': isAvailable,
                'opacity-75 cursor-not-allowed': !isAvailable,
            }"
            :id="`${product.slug}`"
            :dusk="`product-${product.id}`"
            v-bind="$attrs"
            @click="setDialogIsOpen(true)"
            v-if="layout === 'vertical'"
        >
            <ProductLabel :text="product.label" v-if="product.label" />
            <div class="flex flex-col items-center grow p-3">
                <div
                    class="max-w-[96px] md:max-w-[192px] xl:max-w-[256px] py-3"
                    v-if="product.links.image"
                >
                    <ProductImage
                        :name="product.name"
                        :image="product.links.image"
                        :responsive-image="product.links.responsive_image"
                        :lazy-load="lazyLoad"
                    />
                </div>

                <div class="flex flex-row items-center gap-x-2">
                    <ProductTitle :title="product.name" />
                    <ProductId
                        :id="product.custom_id"
                        v-if="product.custom_id"
                    />
                </div>

                <ProductDescription
                    :description="product.description"
                    v-if="product.description"
                />
                <ProductPrice
                    :price="product.total_unit_price"
                    :deposit="product.deposit"
                    class="mb-2"
                />

                <div class="flex flex-row flex-wrap gap-2 mt-auto">
                    <AlcoholicBadge v-if="product.is_alcoholic" />
                    <InvalidTransportationBadge
                        v-if="!isAvailable"
                        :can-deliver="product.for_delivery"
                        :can-pickup="product.for_pickup"
                    />
                    <HalalBadge v-if="product.is_halal" />

                    <SpicyBadge
                        v-if="product.spiciness"
                        :spiciness="product.spiciness"
                    />
                    <ProductAllergies
                        v-for="allergy in product.allergies"
                        :key="allergy.id"
                        :allergy="allergy"
                    />
                </div>
            </div>

            <div class="grow-0 p-3">
                <AddToCartButton
                    :is-adding="form.processing"
                    class="block w-1/2 h-12 mx-auto"
                />
            </div>
        </Card>

        <Card
            class="flex flex-row hover:bg-gray-50 transition-colors ease-in-out duration-150"
            :class="{
                'cursor-pointer': isAvailable,
                'opacity-75 cursor-not-allowed': !isAvailable,
            }"
            :id="`${product.slug}`"
            :dusk="`product-${product.id}`"
            v-bind="$attrs"
            @click="setDialogIsOpen(true)"
            v-if="layout === 'horizontal'"
        >
            <ProductLabel :text="product.label" v-if="product.label" />

            <div class="flex flex-col grow p-3">
                <div class="flex flex-row items-center gap-x-2">
                    <ProductTitle :title="product.name" />
                    <ProductId
                        :id="product.custom_id"
                        v-if="product.custom_id"
                    />
                </div>

                <ProductDescription
                    :description="product.description"
                    v-if="product.description"
                />
                <ProductPrice
                    :price="product.total_unit_price"
                    :deposit="product.deposit"
                    class="mb-2"
                />

                <div class="flex flex-row flex-wrap gap-2 mt-auto">
                    <AlcoholicBadge v-if="product.is_alcoholic" />
                    <InvalidTransportationBadge
                        v-if="!isAvailable"
                        :can-deliver="product.for_delivery"
                        :can-pickup="product.for_pickup"
                    />
                    <HalalBadge v-if="product.is_halal" />

                    <SpicyBadge
                        v-if="product.spiciness"
                        :spiciness="product.spiciness"
                    />
                    <ProductAllergies
                        v-for="allergy in product.allergies"
                        :key="allergy.id"
                        :allergy="allergy"
                    />
                </div>
            </div>

            <div
                class="max-w-[96px] md:max-w-[192px] xl:max-w-[256px] py-3"
                v-if="product.links.image"
            >
                <ProductImage
                    :name="product.name"
                    :image="product.links.image"
                    :responsive-image="product.links.responsive_image"
                    :lazy-load="lazyLoad"
                />
            </div>

            <div class="grow-0 p-3">
                <AddToCartButton
                    :is-adding="form.processing"
                    class="aspect-square"
                />
            </div>
        </Card>
    </div>

    <Dialog
        :is-open="isDialogOpen"
        :set-is-open="setDialogIsOpen"
        :dusk="`product-modal-${product.id}`"
    >
        <template v-if="product.links.image" #header>
            <div class="relative">
                <div class="shadow-[inset_0_-15px_15px_-15px_rgba(0,0,0,0.1)]">
                    <img
                        :src="product.links.image"
                        :srcset="product.links.responsive_image"
                        :alt="product.name"
                        class="relative -z-10 bg-white w-full object-cover rounded-t-xl my-auto"
                        :class="{ 'h-32': !isImageOpen, 'h-full': isImageOpen }"
                        v-if="product.links.image"
                        loading="lazy"
                    />
                </div>

                <div class="absolute left-0 right-0 bottom-[-15px]">
                    <button
                        type="button"
                        class="inline-block bg-white hover:bg-gray-100 rounded-full shadow-lg p-3 transition-colors ease-in-out duration-150"
                        @click="setImageIsOpen(!isImageOpen)"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 512 512"
                            class="fill-gray-700 w-5 h-5 transition-transform ease-in-out duration-150"
                            :class="{ 'rotate-180': isImageOpen }"
                        >
                            <!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                            <path
                                d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                            />
                        </svg>
                        <span class="sr-only">{{
                            isImageOpen
                                ? 'Afbeelding minimaliseren'
                                : 'Afbeelding vergroten'
                        }}</span>
                    </button>
                </div>
            </div>
        </template>

        <div class="flex flex-row items-center gap-x-2">
            <ProductTitle :title="product.name" />
            <ProductId :id="product.custom_id" v-if="product.custom_id" />
        </div>

        <ProductDescription
            :description="product.description"
            v-if="product.description"
        />
        <ProductPrice
            :price="product.total_unit_price"
            :deposit="product.deposit"
            class="mb-2"
        />

        <div class="flex flex-row flex-wrap gap-2 mt-auto">
            <AlcoholicBadge v-if="product.is_alcoholic" />
            <InvalidTransportationBadge
                v-if="!isAvailable"
                :can-deliver="product.for_delivery"
                :can-pickup="product.for_pickup"
            />
            <ProductAllergies
                v-for="allergy in product.allergies"
                :key="allergy.id"
                :allergy="allergy"
            />
        </div>

        <hr class="border-gray-100 my-4" />

        <div class="mb-4" v-if="optionGroups">
            <Fieldset v-for="group in optionGroups" :key="group.id">
                <Legend>
                    {{ group.name }}
                    <span
                        class="text-blue-500 text-sm font-medium ml-2"
                        v-if="group.is_required"
                        >keuze verplicht</span
                    >
                </Legend>

                <MultipleChoiceOptionsSelector
                    v-model="form.option_groups[group.id]"
                    :group="group"
                    v-if="group.type === 'multiple_choice'"
                />
                <OptionalSingleChoiceOptionSelector
                    v-model="form.option_groups[group.id]"
                    :group="group"
                    v-if="group.type === 'single_choice' && !group.is_required"
                />
                <RequiredSingleChoiceOptionSelector
                    v-model="form.option_groups[group.id]"
                    :group="group"
                    v-if="group.type === 'single_choice' && group.is_required"
                />

                <InputError
                    class="mt-2"
                    :message="form.errors[`option_groups.${group.id}`]"
                />
            </Fieldset>
        </div>

        <Fieldset>
            <Legend>Aantal</Legend>
            <QuantityInput v-model.number="form.quantity" />
            <InputError class="mt-2" :message="form.errors.quantity" />
        </Fieldset>

        <template #footer>
            <hr class="border-gray-100" v-if="product.cross_sell_product" />

            <Fieldset
                v-if="product.cross_sell_product"
                class="bg-gray-50 text-left p-6 mb-0!"
            >
                <div class="flex flex-row gap-4">
                    <div
                        class="w-1/3 flex"
                        v-if="product.cross_sell_product.links.image"
                    >
                        <img
                            class="object-contain"
                            :src="product.cross_sell_product.links.image"
                            :srcset="
                                product.cross_sell_product.links
                                    .responsive_image
                            "
                            :alt="product.cross_sell_product.name"
                            v-if="product.cross_sell_product.links.image"
                            loading="lazy"
                        />
                    </div>

                    <div class="flex flex-col grow">
                        <legend class="text-xs text-gray-700">
                            Ook lekker hierbij
                        </legend>

                        <ProductTitle
                            :title="product.cross_sell_product.name"
                        />
                        <ProductPrice
                            :price="product.cross_sell_product.total_unit_price"
                            :deposit="product.cross_sell_product.deposit"
                            class="mb-2"
                        />

                        <div class="mb-2">
                            <QuantityInput
                                variant="small"
                                v-model.number="formCross.quantity"
                            />
                            <InputError
                                class="mt-2"
                                :message="formCross.errors.quantity"
                            />
                        </div>

                        <button
                            class="flex w-64 h-8 bg-white justify-center items-center cursor-pointer font-medium rounded-sm border transition-[border-color] ease-in-out duration-150"
                            :class="{
                                'border-blue-500 text-white px-2 hover:border-blue-600 active:border-blue-600':
                                    !showAddAnimationCross,
                                'px-4 border-green-500 text-white hover:border-green-600 active:border-green-600':
                                    showAddAnimationCross,
                                'px-4': formCross.processing,
                            }"
                            @click="createItemCross"
                            dusk="add-cross-to-cart"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                class="fill-blue-500 w-[16px] h-[16px] animate-spin"
                                v-if="formCross.processing"
                            >
                                <!-- loading -->
                                <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                <path
                                    d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z"
                                />
                            </svg>

                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                                class="fill-green-500 w-[16px] h-[16px]"
                                v-if="
                                    !formCross.processing &&
                                    showAddAnimationCross
                                "
                            >
                                <!-- check -->
                                <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                <path
                                    d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                                />
                            </svg>

                            <span
                                :class="{
                                    'text-blue-500': !showAddAnimationCross,
                                }"
                                v-if="
                                    !formCross.processing &&
                                    !showAddAnimationCross
                                "
                            >
                                Toevoegen
                            </span>
                        </button>
                    </div>
                </div>
            </Fieldset>

            <div class="flex flex-row">
                <button
                    class="flex w-full h-16 justify-center items-center cursor-pointer bg-red-500 text-white hover:bg-red-600 active:bg-red-600 disabled:bg-red-300 font-medium rounded-bl-xl transition-[background-color] ease-in-out duration-150"
                    @click="setDialogIsOpen(false)"
                    dusk="close-product-modal"
                >
                    Sluiten
                </button>

                <button
                    class="flex w-full h-16 justify-center items-center cursor-pointer font-medium rounded-br-xl transition-[background-color] ease-in-out duration-150"
                    :class="{
                        'bg-blue-500 text-white hover:bg-blue-600 active:bg-blue-600 disabled:bg-blue-300':
                            !showAddAnimation,
                        'bg-green-500 text-white hover:bg-green-600 active:bg-green-600 disabled:bg-green-300':
                            showAddAnimation,
                    }"
                    @click="createItem"
                    dusk="add-to-cart"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        class="fill-white w-[16px] h-[16px] animate-spin"
                        v-if="form.processing"
                    >
                        <!-- loading -->
                        <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                        <path
                            d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z"
                        />
                    </svg>

                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        class="fill-white w-[16px] h-[16px]"
                        v-if="!form.processing && showAddAnimation"
                    >
                        <!-- check -->
                        <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                        <path
                            d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                        />
                    </svg>

                    <span
                        class="text-white"
                        v-if="!form.processing && !showAddAnimation"
                    >
                        {{ price(totalPrice) }} Toevoegen
                    </span>
                </button>
            </div>
        </template>
    </Dialog>
</template>
