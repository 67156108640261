<script setup>
import route from '@/Helpers/route.js';
import CardLink from '@/Components/Shared/Cards/CardLink.vue';
import Dialog from '@/Components/Shared/Dialogs/Dialog.vue';
import { usePage } from '@inertiajs/vue3';
import CrossSellProduct from '@/Components/Shared/Restaurant/CrossSellProduct.vue';

const props = defineProps({
    products: Array,
    isDialogOpen: Boolean,
    setDialogOpen: Function,
});

const page = usePage();
</script>

<template>
    <Dialog class="max-w-2xl!" :is-open="isDialogOpen" :set-is-open="setDialogOpen">
        <h3 class="font-semibold text-lg xl:text-2xl text-gray-700 leading-tight mb-8">Ook lekker erbij</h3>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-y-12 gap-x-8">
            <template
                v-for="product in products"
                :key="product.id"
            >
                <CrossSellProduct :product="product" />
            </template>
        </div>

        <template #footer>
            <CardLink
                :href="route(`${page.props.routing_group}.checkout`, { [page.props.routing_key]: page.props.routing_target })"
                variant="green"
            >
                Doorgaan naar afrekenen
            </CardLink>
        </template>
    </Dialog>
</template>
