<script setup>
import { ref, watch } from 'vue';

const props = defineProps({
    isAdding: Boolean,
});

// plus, loading, or checkmark
const animation = ref('plus');

watch(() => props.isAdding, (isAdding) => {
    if (isAdding) {
        animation.value = 'loading';
    } else {
        animation.value = 'checkmark';
        setTimeout(() => {
            animation.value = 'plus';
        }, 250);
    }
});
</script>

<template>
    <button
        class="flex justify-center items-center px-4 py-2 rounded-lg transition-[background-color] duration-50"
        :class="{ 'bg-blue-200 hover:bg-blue-300 text-blue-500': ['plus', 'loading'].includes(animation), 'bg-green-200 text-green-500': ['checkmark'].includes(animation)}"
        dusk="add-to-cart">

        <template v-if="animation === 'plus'">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="fill-blue-500 w-[16px] h-[16px]"> <!-- plus -->
                <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/>
            </svg>
            <span class="sr-only">Toevoegen aan winkelmand</span>
        </template>

        <template v-else-if="animation === 'loading'">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="fill-blue-500 w-[16px] h-[16px] animate-spin"> <!-- loading -->
                <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                <path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z"/>
            </svg>
            <span class="sr-only">Aan het laden</span>
        </template>

        <template v-else-if="animation === 'checkmark'">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="fill-green-500 w-[16px] h-[16px]"> <!-- check -->
                <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/>
            </svg>
            <span class="sr-only">Toegevoegd aan winkelmand</span>
        </template>

    </button>
</template>
